import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Aktuelles from '../components/Aktuelles/Aktuelles';
import Header from '../components/Header/Header';
import Linkitem from '../components/Linkitems/Linkitem';
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';
import FilterMenu from '../components/FilterMenu';
import CarouselNew from '../components/Carousel/CarouselNew';
import ImmoCard from '../components/Cards/ImmoCard';
import SearchProfileWithouthImmos from '../components/Cards/SearchProfileWithouthImmos';
import LinkToSearchProfiles from '../components/LinkToSearchProfiles';

import headerImageMobileVerkauf from '../assets/images/mysreal_HerotemplateMobil_593x950px-Martin-Phase4.jpg';
import headerImageVerkauf from '../assets/images/s-REAL-Sujets-2023-Webhero-16zu9-Martin-Phase4.jpg';
import headerImageMobileKauf from '../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0004_s-REAL-Sujets-2023-Webhero-16zu9-1.13.jpg';
import headerImageKauf from '../assets/images/s-REAL-Sujets-2023-Webhero-16zu-Mariam-Phase4.jpg';
import SatisfactionCard from '../components/Cards/SatisfactionCard';
import MetaTags from '../components/MetaTags';

import MainContent from '../components/MainContent';
import NewsTeaserCards from '../components/News/NewsTeaserCards';

import VorteilsweltCards from '../components/Cards/VorteilsweltCards';

// ICONS
import IconCalendarHouse from '../components/Icons/IconCalendarHouse';
import IconNews from '../components/Icons/IconNews';
import IconMailOutlined from '../components/Icons/IconMailOutlined';
import IconHouseSearch from '../components/Icons/IconHouseSearch';
import IconCheckmarkRoundOutlined from '../components/Icons/IconCheckmarkRoundOutlined';
import IconSearch from '../components/Icons/IconSearch';

import { anliegenOptions } from '../components/FormFields/util/formOptions';
import { CONTACTFORM_ANLIEGEN_VERKAUF, MENU_VERKAUF, MENU_KAUF } from '../constants';

// STORE
import InfoDialogKaufvertrag from '../components/Dialogs/InfoDialogKaufvertrag';
import { useHandleMessages } from '../hooks/messages/useHandleMessages';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import { setPageDialogOpenContactForm, setReasonContactForm } from '../reducers/contactInformation';
import { useFetchSearchprofiles } from '../hooks/searchprofiles/useFetchSearchprofiles';
import { useHandleSimilarImmos } from '../hooks/immo/useHandleSimilarImmos';
import LinkCardToAlleAngeboteSmall from '../components/Cards/LinkCardToAlleAngeboteSmall';
import { parseImmoSreal } from '../util/immoObjectParser';
import ImmoCardSmall from '../components/Cards/ImmoCardSmall';
import LinkCardToAlleAngebote from '../components/Cards/LinkCardToAlleAngebote';

function KaeuferPhase4() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);
  const [openInfoDialogKV, setOpenInfoDialogKV] = useState(false);
  const [satisfactionSurveyCompleted, setSatisfactionSurveyCompleted] = useState(false);

  const handleOpenInfoDialogKV = () => {
    let newVal = !openInfoDialogKV;
    setOpenInfoDialogKV(newVal);
  };

  const { isMobile, isTablet } = useHandleScreenResize();
  const { files } = useHandleUserRealtyActive();
  const { threads, threadsLoading, loadMessages } = useHandleMessages();

  const { getSearchprofilesMenu } = useFetchSearchprofiles();
  const [suchprofileMenu, setSuchprofileMenu] = useState(null);
  const [similarImmos, setSimilarImmos] = useState([]);
  const { getSimilarImmos } = useHandleSimilarImmos();

  useEffect(() => {
    setSuchprofileMenuState();
    if (user.satisfactionSurveyCompleted) {
      setSatisfactionSurveyCompleted(user.satisfactionSurveyCompleted);
    }
  }, [user]);

  const setSuchprofileMenuState = () => {
    setSuchprofileMenu(getSearchprofilesMenu());
  };

  useEffect(() => {
    loadMessages();
  }, []);

  const handleWeitereImmos = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  useEffect(() => {
    const fetchSimilarImmos = async () => {
      try {
        const activeProfiles = user.searchProfiles.filter((sp) => sp.state === 'active');
        const immos = await getSimilarImmos(activeProfiles);
        setSimilarImmos(immos.slice(0, 8) || []);
      } catch (error) {
        console.error('Error fetching similar immos:', error);
      }
    };
    fetchSimilarImmos();
  }, [user.searchProfiles]);

  const [activeSuchprofileIndex, setActiveSuchprofileIndex] = useState(0);
  const handleSetActiveSuchprofileIndex = (event) => {
    let index = parseInt(event.target.dataset.index);
    setActiveSuchprofileIndex(index);
  };

  return (
    <>
      <MetaTags title={'Kaufvertrag | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-kauf.jpg'} />

      {app.menuType === MENU_VERKAUF && (
        <Header cssClasses="header--default header--withimage">
          <img src={!isMobile ? headerImageVerkauf : headerImageMobileVerkauf} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
          <div className="header__content-wrapper" id="header-dashboard4">
            <IconCheckmarkRoundOutlined color="#fff" />
            <div className="w-100">
              <h1 className="text-white">Gratulation, Ihr Verkauf ist abgeschlossen.</h1>
              {/*<p className="textlink text-white mt-100rem" onClick={() => navigate(app.root + '/statistik')}>
                Zusammenfassung anzeigen
              </p>*/}
            </div>
          </div>
        </Header>
      )}

      {app.menuType === MENU_KAUF && (
        <>
          <Header cssClasses="header--default header--fitcontent header-overflowhidden">
            <img src={!isMobile ? headerImageKauf : headerImageMobileKauf} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
            <div className="header__content-wrapper" id="header__suchprofile">
              <div className="header__suchprofile--top">
                <h1 className="text-white">Meine Angebote</h1>
                <div className="header__suchprofile--topfiltermenu mobile-hidden">
                  {suchprofileMenu && (
                    <FilterMenu
                      categories={suchprofileMenu}
                      activeIndex={activeSuchprofileIndex}
                      handleClick={handleSetActiveSuchprofileIndex}
                      cssClasses="filtermenu--inverted"
                      cutside={isMobile ? 'none' : 'right'}
                    />
                  )}
                  <button className="button button--white position-relative button--round" style={{ padding: '0.6rem' }} onClick={() => navigate('../immobiliensuche')}>
                    <IconSearch color="#2870ED" cssClasses={'position-center'} />
                  </button>
                </div>

                <div className="header__suchprofile--topfiltermenu desktop-hidden">
                  <button className="button button--white position-relative button--round" style={{ padding: '0.6rem' }} onClick={() => navigate('../immobiliensuche')}>
                    <IconSearch color="#2870ED" cssClasses={'position-center'} />
                  </button>
                </div>
                <div className="header__suchprofile--topfiltermenu desktop-hidden">
                  {suchprofileMenu && (
                    <FilterMenu
                      categories={suchprofileMenu}
                      activeIndex={activeSuchprofileIndex}
                      handleClick={handleSetActiveSuchprofileIndex}
                      cssClasses="filtermenu--inverted"
                      cutside={isMobile ? 'none' : 'right'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="header__content-wrapper mt-0" id="header__suchprofile">
              <LinkToSearchProfiles cssClasses="w-100 textlink text-white text-nodecoration text-center hover-underline" linkText={'Suchprofile bearbeiten'} />
            </div>
            {suchprofileMenu &&
              suchprofileMenu.map((sp, index) => {
                let spMenuClass = 'header__suchprofile--immos';
                if (sp.key !== activeSuchprofileIndex) spMenuClass += ' is-hidden';

                let immos = sp.immos?.filter((immo) => immo.id);

                return (
                  <div key={index}>
                    {immos.length > 0 ? (
                      <CarouselNew cssClasses={spMenuClass} isMobile={isMobile}>
                        {immos.map((immo) => {
                          let status = '';
                          if (immo.activities.some((activity) => activity.id === '1591')) {
                            status = 'aktiv';
                          } else if (immo.activities.some((activity) => activity.id === '1594')) {
                            status = 'abgeschlossen';
                          }
                          return <ImmoCard retailObject={immo} key={immo.id} status={status} />;
                        })}
                        <LinkCardToAlleAngebote />
                      </CarouselNew>
                    ) : (
                      <CarouselNew cssClasses={spMenuClass} isMobile={isMobile}>
                        <SearchProfileWithouthImmos cssClasses={spMenuClass} />
                      </CarouselNew>
                    )}
                  </div>
                );
              })}
          </Header>

          {similarImmos.length > 0 ? (
            <div className="header__similar-immos pt-150rem">
              <div className="header__similar-immos__inner mb-150rem">
                <div className="header__similar-immos__header-wrapper">
                  <div className="header__similar-immos__header mb-50rem">
                    <span className="text-white text-bold">Das könnte Ihnen auch gefallen</span>
                  </div>
                </div>
                <CarouselNew isMobile={isMobile} fourslides={!isTablet}>
                  {similarImmos.map((immo) => {
                    return <ImmoCardSmall retailObject={parseImmoSreal(immo)} key={immo.id} />;
                  })}
                  <LinkCardToAlleAngeboteSmall />
                </CarouselNew>
              </div>
            </div>
          ) : null}
        </>
      )}

      <MainContent>
        <div className="gray-background mt-0">
          <Aktuelles />
        </div>

        <div className="w-desktop--center">
          {app.menuType === MENU_VERKAUF && <h2 className="mt-200rem pb-100rem text-center--mobile">Nach dem Verkauf</h2>}
          {app.menuType === MENU_KAUF && <h2 className="mt-200rem pb-100rem text-center--mobile">Nach dem Kauf</h2>}

          {/* WEITEREMPFEHLUNGSCARD - NICHT LÖSCHEN
          {!localStorage.getItem('mysreal-satisfaction-isset') || localStorage.getItem('mysreal-satisfaction-isset' === false) ? (
            <>
              <div className={'elements3cols'}>
                <SatisfactionCard cssClasses={'elements3cols__col'} />
                <RecommendationCard cssClasses={'elements3cols__col'} />
                {files.length > 0 ? <ImmoDriveCard cssClasses={'elements3cols__col'} files={files} smallCard={true} /> : <ImmoDriveEmptyCard cssClasses={'elements3cols__col'} smallCard={true} />}
              </div>
            </>
          ) : (
            <>
              <div className={'elements2cols'}>
                <RecommendationCard cssClasses={'elements2cols__col'} />
                {files.length > 0 ? <ImmoDriveCard cssClasses={'elements2cols__col'} files={files} smallCard={true} /> : <ImmoDriveEmptyCard cssClasses={'elements2cols__col'} smallCard={true} />}
              </div>
            </>
          )}
          */}
          <div className={'elements2cols'}>
            {!satisfactionSurveyCompleted && <SatisfactionCard cssClasses={'elements2cols__col'} />}
            {files.length > 0 ? <ImmoDriveCard cssClasses={'elements2cols__col'} files={files} smallCard={true} /> : <ImmoDriveEmptyCard cssClasses={'elements2cols__col'} smallCard={true} />}
          </div>
        </div>

        <div className={'p-100rem w-desktop--center'}>
          <h2 className="text-center--mobile">Sie besitzen eine weitere Immobilie?</h2>
          <Linkitem
            cssClasses="linkitem--blue linkitem--einzeilig-desktop linkitem--IconHouseSearch mb-400rem"
            handleLink={handleWeitereImmos}
            icon={<IconHouseSearch />}
            headline="Weitere Immobilie verkaufen"
            text="Unsere Immobilienexpert:innen beraten Sie gerne"
          />
        </div>
        <div className="gray-background">
          <div className="pt-300rem w-desktop--center">
            <h2 className="mt-0 pb-100rem">Die s REAL Vorteilswelt</h2>
            <div className="cardgrid">
              <VorteilsweltCards
                color="background-pink"
                headline="s REAL Newsletter"
                text="Unsere Newsletterfamilie ist gewachsen! Jetzt anmelden und keine Neuheiten verpassen."
                icon={<IconMailOutlined color="#fff" />}
              >
                <a href="https://www.sreal.at/de/newsletter" rel="noreferrer" title="s REAL Newsletter" className="button button--blue" target="_blank">
                  s REAL Newsletter abonnieren
                </a>
              </VorteilsweltCards>
              <VorteilsweltCards color="background-aubergine" headline="s REAL Wohnen Magazin" text="Blättern Sie durch die neue s REAL Magazin-Ausgabe." icon={<IconNews color="#fff" />}>
                <a href="https://www.sreal.at/de/magazin" rel="noreferrer" target="_blank" title="s REAL Wohnen Magazin" className="button button--blue">
                  Magazin anzeigen
                </a>
              </VorteilsweltCards>
              <VorteilsweltCards
                color="background-stone"
                headline="s REAL Immoblog"
                text="Sie interessieren sich für die Immobilienwelt? Hier finden Sie wissenswerte Informationen rund um Immobilien und Wohnen."
                icon={<IconCalendarHouse color="#fff" />}
              >
                <a href="https://www.sreal.at/de/immoblog" rel="noreferrer" target="_blank" title="s REAL Immoblog" className="button button--blue">
                  Immoblog anzeigen
                </a>
              </VorteilsweltCards>
            </div>
          </div>
        </div>

        <NewsTeaserCards cssClasses="w-desktop--slider mt-400rem" />

        <InfoDialogKaufvertrag open={openInfoDialogKV} handleClose={handleOpenInfoDialogKV} />
      </MainContent>
    </>
  );
}

export default KaeuferPhase4;

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../reducers/app";
import { setResetFieldsCachedNews } from "../../reducers/cachedNews";
import { setResetFieldsCachesRealties } from "../../reducers/cachedRealties";
import { setPageDialogOpenContactForm, setResetFields } from "../../reducers/contactInformation";
import { setPageDialogOpenKaufanbotForm, setResetFieldsKaufanbotForm } from "../../reducers/kaufAnbote";
import { setPageDialogOpenMarktinfoForm, setResetFieldsMarktinfoLightForm } from "../../reducers/marktinfoLight";
import { setResetFieldsSearchForm } from "../../reducers/searchForm";
import { setPageDialogOpenSPForm, setResetFieldsSPForm } from "../../reducers/searchProfileForm";
import { setResetFieldsUser } from "../../reducers/user";
import { setPageDialogOpenWohnwertForm, setResetFieldsWohnwertForm } from "../../reducers/wohnwertLight";

import { useHandleMenu } from "../menu/useHandleMenu";
import { setPageDialogOpenLoginForm } from '../../reducers/loginForm';
import { setPageDialogOpenRegisterForm } from '../../reducers/registerForm';
import { setPageDialogOpenObjectRequest } from '../../reducers/objectRequest';

export const useHandleApp = () => {
    const app = useSelector((state) => state.app);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setMenuByPhase } = useHandleMenu();

    const resetStore = () => {
        dispatch(clearStorage());
        dispatch(setResetFields());
        dispatch(setResetFieldsUser());

        dispatch(setResetFieldsMarktinfoLightForm());
        dispatch(setResetFieldsWohnwertForm());
        dispatch(setResetFieldsSPForm());
        dispatch(setResetFieldsSearchForm());
        dispatch(setResetFieldsKaufanbotForm());
        dispatch(setResetFieldsCachedNews());
        dispatch(setResetFieldsCachesRealties());

        setMenuByPhase();

        localStorage.removeItem('activityIdsRead');
    };

    const closePageDialogs = () => {
      dispatch(setPageDialogOpenContactForm(false));
      dispatch(setPageDialogOpenKaufanbotForm(false));
      dispatch(setPageDialogOpenMarktinfoForm(false));
      dispatch(setPageDialogOpenMarktinfoForm(false));
      dispatch(setPageDialogOpenSPForm(false));
      dispatch(setPageDialogOpenWohnwertForm(false));
      dispatch(setPageDialogOpenLoginForm(false));
      dispatch(setPageDialogOpenRegisterForm(false));
      dispatch(setPageDialogOpenObjectRequest(false));
    }

  return { resetStore, closePageDialogs };
}
import { parseDate } from './datetimeParser';
import { isImage } from './generalUtils';

function getDefaultNewsObject() {
  let defaultObject = {
    title: '',
    slug: '',
    summary: '',
    topics: '',
    topicsArray: [],
    blocks: [],
    author_name: '',
    header_picture: '',
    header_picture_small: '',
		header_picture_copyright: '',
		header_picture_alt: '',
    updated_at: '',
    published_on: '',
    external: false,
    source: '',
    hero_rank: null,
  };

  return defaultObject;
}

const newsParser = (data) => {
  if (!data) return null;
  let newsObject = getDefaultNewsObject();

  if (data.title) {
    newsObject.title = data.title;
  }

  if (data.slug) {
    newsObject.slug = data.slug;
  }

  if (data.summary) {
    newsObject.summary = data.summary;
  }

  if (data.topics?.length > 0) {
    let topics = data.topics
      .map((t) => {
        // put topic string together
        return t.name.toUpperCase().replace(',', '');
      })
      .join(', ');

    newsObject.topics = topics;
    newsObject.topicsArray = data.topics;
  }

  if (data.authors) {
    let author_names = [];
    data.authors.forEach((author) => {
      if (author.first_name && author.last_name) author_names.push(`${author.first_name} ${author.last_name}`);
    });
    newsObject.author_name = author_names.join(', ');
  }

  if (data.cover) {
		if(data.cover.url) {
			let fileString = data.cover.url;
			if (isImage(fileString)) {
				newsObject.header_picture = fileString;
			}
		}
		
		newsObject.header_picture_copyright = data.cover.copyright;
		newsObject.header_picture_alt = data.cover.description ? data.cover.description : data.title;
  }


  if (data.updated_at) {
    newsObject.updated_at = parseDate(data.updated_at);
  }

  if (data.published_on) {
    newsObject.published_on = parseDate(data.published_on);
  }

  if (data.blocks) {
    newsObject.blocks = data.blocks;
  }

  if (data.external) {
    newsObject.external = data.external;
  }

  if (data.source) {
    newsObject.source = data.source;
  }

  return newsObject;
};

export { newsParser, getDefaultNewsObject };

import httpClient from './index';

const END_POINT = '/Immo';

const realtyInquiry = async (formData) => {
  const { gender, titlePre, titlePost, firstName, lastName, email, phone, message, newsletter, cancellationNoticeConfirmed } = formData;

  try {
    const resp = await httpClient.get(`${END_POINT}/realtyInquiry`, {
      gender,
      titlePre,
      titlePost,
      firstName,
      lastName,
      email,
      phone,
      message,
      newsletter,
      cancellationNoticeConfirmed,
    });

    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getRealtyList = async (parent_id, tenant_id) => {
  if (!parent_id) return false;

  try {
    const resp = await httpClient.get(`${END_POINT}/getRealtyList?parent_id=${parent_id}&tenant_id=${tenant_id}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getRealtyDetails = async (id, tenant_id, token) => {
  if (!id || !tenant_id) return false;

  let tokenAddon = '';
  if (token) {
    tokenAddon = '?token=' + token;
  }

  try {
    const resp = await httpClient.get(`${END_POINT}/getRealty/${id}/${tenant_id}${tokenAddon}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getRealtyPOICategories = async (id, tenant_id) => {
  if (!id || !tenant_id) return false;

  try {
    const resp = await httpClient.get(`${END_POINT}/realties/${id}/${tenant_id}/poi/categories?locale=de&precise=1`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

// iso3 ist die Landesbezeichnung als string mit 3 Buchstaben
// fürs erste wird hier immer "AUT" übergeben, weiß nicht ob wir das überhaupt für andere Länder brauchen
const autocompleteFederalState = async (iso3) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/autocompleteFederalState/${iso3 ? iso3 : 'AUT'}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const autocompleteZip = async (zip) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/autocompleteZip/${zip}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const autocompleteCity = async (zip) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/autocompleteCity/${zip}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const autocompleteQuery = async (query) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/autocomplete/${query}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

async function autocomplete(value) {
  try {
    let autoCompleteResponse = await autocompleteQuery(value);
    const result = [];
    autoCompleteResponse.map((item) => {
      switch (item.type) {
        /*case 0:
          result.push({
            label: 'Bundesland',
            value: 'optgroup_Bundesland',
            disabled: true
          });
          item.items.map((item_inner) => {
            result.push({
              label: item_inner.text,
              value: item_inner.id.toString(),
              type: 1
            });
          });
          break;*/
        case 1:
          result.push({
            label: 'Bezirk',
            value: 'optgroup_Bezirk',
            disabled: true,
          });
          item.items.map((item_inner) => {
            result.push({
              label: item_inner.text,
              value: item_inner.id.toString(),
              type: item.type,
            });
          });
          break;
        case 2:
          result.push({
            label: 'Stadtgemeinde',
            value: 'optgroup_Stadtgemeinde',
            disabled: true,
          });
          item.items.map((item_inner) => {
            result.push({
              label: item_inner.text,
              value: item_inner.id.toString(),
              type: item.type,
            });
          });
          break;
        case 3:
          result.push({
            label: 'Gemeinde',
            value: 'optgroup_Gemeinde',
            disabled: true,
          });
          item.items.map((item_inner) => {
            result.push({
              label: item_inner.text,
              value: item_inner.id.toString(),
              type: item.type,
            });
          });
          break;
        case 4:
          result.push({
            label: 'PLZ',
            value: 'optgroup_PLZ',
            disabled: true,
          });
          item.items.map((item_inner) => {
            result.push({
              label: item_inner.text,
              value: item_inner.id.toString(),
              type: item.type,
            });
          });
          break;
        case 5:
          result.push({
            label: 'Ort / PLZ',
            value: 'optgroup_Ort_PLZ',
            disabled: true,
          });
          item.items.map((item_inner) => {
            result.push({
              label: item_inner.text,
              value: item_inner.id.toString(),
              type: item.type,
            });
          });
          break;
        default:
          break;
      }
    });
    return result;
  } catch (e) {
    throw e;
  }
}

const getMySearchProfiles = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}/contacts/searchProfiles`, {
      params: {
        fields: '*',
        locale: 'de',
      },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getFavorites = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}/realty/favorite`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const setFavorite = async (realtyId, tenantId) => {
  if (!realtyId || !tenantId) return false;

  try {
    const resp = await httpClient.post(`${END_POINT}/realty/favorite?realtyId=${realtyId}&tenantId=${tenantId}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const deleteFavorite = async (realtyFavoriteId) => {
  if (!realtyFavoriteId) return false;

  try {
    const resp = await httpClient.delete(`${END_POINT}/realty/favorite?realtyFavoriteId=${realtyFavoriteId}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const createSearchProfile = async (searchProfileName, searchProfileData) => {
  if (!searchProfileName || !searchProfileData) return false;

  try {
    const resp = await httpClient.post(`${END_POINT}/contacts/searchProfiles/${searchProfileName}`, searchProfileData);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const updateSearchProfile = async (searchProfileId, searchProfileData) => {
  if (!searchProfileId) throw new Error('searchProfileId is missing');
  if (!searchProfileData) throw new Error('searchProfileData is missing');

  try {
    const resp = await httpClient.put(`${END_POINT}/contacts/searchProfile/${searchProfileId}`, searchProfileData);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const renameSearchProfile = async (searchProfileId, name) => {
  try {
    const resp = await httpClient.put(`${END_POINT}/contacts/searchProfilesName/${searchProfileId}/${name}`);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getSearchProfile = async (searchProfileId, locale) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/contacts/searchProfiles/${searchProfileId}?locale=${locale ? locale : 'de'}`);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const putSearchProfileActive = async (searchProfileId, active) => {
  try {
    const resp = await httpClient.put(`${END_POINT}/contacts/searchProfilesActive/${searchProfileId}/${active}`);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getOfferRealtyList = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}/offerRealtyList?locale=de`);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getRealtyFile = async (realtyId, tenantId, storageKey, token) => {
  let tokenAddon = '';
  if (token) {
    tokenAddon = '?token=' + token;
  }

  try {
    const resp = await httpClient.get(`${END_POINT}/getRealtyFile/${realtyId}/${tenantId}/${storageKey}/de${tokenAddon}`, {
      responseType: 'blob',
    });
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getSearchProfilesResult = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}/contacts/searchProfilesResult?locale=de`);
    return resp.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const postSendOffer = async (tenantId, realtyId, price) => {
  if (!tenantId || !realtyId || !price || parseInt(price) === 0) {
    throw new Error('Required data ist missing.');
  }

  try {
    const resp = await httpClient.post(`${END_POINT}/sendOffer?tenantId=${tenantId}&realtyId=${realtyId}&price=${price}`);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getZipsForLocations = async ({ city_id, municipality_id, political_district_id }) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/location/zips`, {
      params: {
        tenant_id: 3255,
        city_id,
        municipality_id,
        political_district_id,
      },
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export {
  getRealtyList,
  getRealtyDetails,
  autocomplete,
  createSearchProfile,
  getMySearchProfiles,
  renameSearchProfile,
  getSearchProfile,
  putSearchProfileActive,
  getRealtyFile,
  getRealtyPOICategories,
  getFavorites,
  setFavorite,
  deleteFavorite,
  getOfferRealtyList,
  getSearchProfilesResult,
  postSendOffer,
  updateSearchProfile,
  getZipsForLocations,
};
